import PropTypes from 'prop-types';
import classNames from 'classnames';

const ContentBlock = ({ title, subtitle, name, children }) => {
  const classes = classNames({
    'content-block': true,
    [`content-block--${name}`]: !!name,
  });

  return (
    <div className={classes}>
      <h3 className="content-block__heading">{title}</h3>
      {subtitle && <p className="content-block__subheading">{subtitle}</p>}
      <div className="content-block__content">{children}</div>
    </div>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default ContentBlock;
