import React from 'react';
import PropTypes from 'prop-types';

// components
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import { i18n as I18n } from '../../../utils/i18n';

const NextBtn = ({ onClick }) => {
  return (
    <button className="right-arrow">
      <ReactSVG
        src="../../../images/icons/homepage/testimonials/right-arrow.svg"
        onClick={onClick}
      />
    </button>
  );
};

NextBtn.propTypes = {
  onClick: PropTypes.func,
};

const PrevBtn = ({ onClick }) => {
  return (
    <button className="left-arrow">
      <ReactSVG
        src="../../../images/icons/homepage/testimonials/left-arrow.svg"
        onClick={onClick}
      />
    </button>
  );
};

PrevBtn.propTypes = {
  onClick: PropTypes.func,
};

const TextSlideShow = ({ slidesArray }) => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    duration: 1000,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <NextBtn />,
    prevArrow: <PrevBtn />,
  };

  const slides = slidesArray.map((text, index) => (
    <div className="text-slide" key={index}>
      <div className="text-wrap">
        <h2>{I18n.t(text.quote)}</h2>
        <p>{I18n.t(text.by)}</p>
      </div>
    </div>
  ));

  return <Slider {...settings}>{slides}</Slider>;
};

TextSlideShow.propTypes = {
  slidesArray: PropTypes.array.isRequired,
};

export default TextSlideShow;
