import { i18n as I18n, withTranslation } from '../utils/i18n';

import Page from '../components/page';
import ContentBlock from '../components/contentBlock';
import ContentBlockItem from '../components/contentBlockItem';
import QuickSearch from '../containers/quickSearch/index.tsx';
import RoomTypesExplainer from '../components/roomTypesExplainer';

import TextSlideShow from '../components/shared/textSlideShow';

const HomePage = () => {
  const testimonials = I18n.t('testimonials.items', { returnObjects: true });

  return (
    <Page
      title={I18n.t('headers.homepage.title')}
      description={I18n.t('headers.homepage.description')}
    >
      <div className="banner" data-cy="banner">
        <div className="inner">
          <div className="banner__content">
            <h1 className="banner__heading">{I18n.t('home.title')}</h1>
            <p className="banner__intro">{I18n.t('home.subtitle')}</p>
          </div>
          <div className="banner__quickSearch">
            <QuickSearch />
          </div>
        </div>
      </div>
      <main className="home_page">
        <RoomTypesExplainer
          heading={I18n.t('home.room_types.title')}
          intro={I18n.t('home.room_types.subtitle')}
          showItemButton={false}
          roomCategory="both"
          wrapImageWithLink
          wrapHeadingWithLink
          displayExplainerItemText={false}
        />

        <div className="video-block">
          <div className="video-block__wrapper">
            <iframe
              title="vimeo-player"
              src={I18n.t('vimeo-player.source')}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>

        <ContentBlock
          title={I18n.t('core_aims_block.title')}
          subtitle={I18n.t('core_aims_block.subtitle')}
          name="core-aims"
        >
          <ContentBlockItem
            image="/images/icons/homepage/core-aims/flexibility.png"
            title={I18n.t('core_aims_block.flexibility')}
          />
          <ContentBlockItem
            image="/images/icons/homepage/core-aims/transparency.png"
            title={I18n.t('core_aims_block.transparency')}
          />
          <ContentBlockItem
            image="/images/icons/homepage/core-aims/communities.png"
            title={I18n.t('core_aims_block.communities')}
          />
          <ContentBlockItem
            image="/images/icons/homepage/core-aims/scalability.png"
            title={I18n.t('core_aims_block.scalability')}
          />
        </ContentBlock>

        <div className="testimonials-slideshow">
          <h1>{I18n.t('testimonials.title')}</h1>
          <TextSlideShow slidesArray={testimonials} />
        </div>
      </main>
    </Page>
  );
};

HomePage.getInitialProps = async () => ({
  namespacesRequired: ['common'],
});

export default withTranslation('common')(HomePage);
