import React from 'react';
import PropTypes from 'prop-types';

// components
import RoomTypesExplainerItem from '../roomTypeExplainerItem';

// constants
import {
  clinicalRoomTypes,
  nonClinicalRoomTypes,
} from '../../constants/roomTypes';

const RoomTypesExplainer = ({
  heading,
  intro,
  showItemButton,
  roomCategory,
  wrapImageWithLink,
  wrapHeadingWithLink,
  displayExplainerItemText,
}) => {
  const renderRoomTypes = (rooms, category) => {
    return (
      <div
        className={`room-types-explainer__content room-types-explainer--${category}`}
      >
        {rooms.map((room, index) => (
          <RoomTypesExplainerItem
            {...room}
            key={index}
            showButton={showItemButton}
            wrapImageWithLink={wrapImageWithLink}
            wrapHeadingWithLink={wrapHeadingWithLink}
            displayText={displayExplainerItemText}
          />
        ))}
      </div>
    );
  };

  const renderRooms = category => {
    switch (category) {
      case 'clinical':
        return renderRoomTypes(clinicalRoomTypes, category);
      case 'non-clinical':
        return renderRoomTypes(nonClinicalRoomTypes, category);
      default: {
        return [
          renderRoomTypes(clinicalRoomTypes, 'clinical'),
          renderRoomTypes(nonClinicalRoomTypes, 'non-clinical'),
        ];
      }
    }
  };

  return (
    <section className="room-types-explainer" id="alternative-room-types">
      <h1 className="room-types-explainer__heading">{heading}</h1>
      <h4 className="room-types-explainer__intro">{intro}</h4>
      {renderRooms(roomCategory)}
    </section>
  );
};

RoomTypesExplainer.propTypes = {
  heading: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  showItemButton: PropTypes.bool.isRequired,
  roomCategory: PropTypes.oneOf(['clinical', 'non-clinical', 'both'])
    .isRequired,
  wrapImageWithLink: PropTypes.bool,
  wrapHeadingWithLink: PropTypes.bool,
  displayExplainerItemText: PropTypes.bool,
};

export default RoomTypesExplainer;
