import PropTypes from 'prop-types';

const ContentBlockItem = ({ image, title }) => {
  return (
    <div className="content-block__block">
      <div className="content-block__icon">
        <img src={image} alt={title} />
      </div>
      <div className="content-block__text">{title}</div>
    </div>
  );
};

ContentBlockItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContentBlockItem;
