import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';

const RoomTypesExplainerItem = ({
  imageUrl,
  imageAlt,
  heading,
  text,
  link,
  buttonText,
  showButton,
  wrapImageWithLink,
  wrapHeadingWithLink,
  displayText,
}) => {
  const textClasses = classNames({
    'room-types-explainer__item__text': true,
    'room-types-explainer__item__text--hidden': !displayText,
  });

  const renderImage = () => {
    return wrapImageWithLink ? (
      <Link href={link}>
        <a href={link} data-testid="roomTypeImageLink">
          <img
            src={imageUrl}
            alt={imageAlt}
            className="room-types-explainer__image-with-link"
            data-cy="roomTypeImageWithLink"
          />
        </a>
      </Link>
    ) : (
      <img src={imageUrl} alt={imageAlt} />
    );
  };

  const renderHeading = () => {
    return wrapHeadingWithLink ? (
      <Link href={link}>
        <a href={link} className="room-types-explainer__heading-with-link">
          <h2 data-cy="roomTypeHeadingWithLink">{heading}</h2>
        </a>
      </Link>
    ) : (
      <h2>{heading}</h2>
    );
  };

  return (
    <div className="room-types-explainer__item">
      {renderImage()}
      {renderHeading()}
      <p className={textClasses}>{text}</p>
      {showButton && (
        <Link href={link}>
          <a className="button button--medium button--primary">{buttonText}</a>
        </Link>
      )}
    </div>
  );
};

RoomTypesExplainerItem.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  showButton: PropTypes.bool,
  wrapImageWithLink: PropTypes.bool,
  wrapHeadingWithLink: PropTypes.bool,
  displayText: PropTypes.bool,
};

RoomTypesExplainerItem.defaultProps = {
  showButton: true,
  wrapImageWithLink: false,
  wrapHeadingWithLink: false,
  displayText: true,
};

export default RoomTypesExplainerItem;
