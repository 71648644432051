import { i18n as I18n } from '../utils/i18n';

export const clinicalRoomTypes = [
  {
    imageUrl: '../images/landing/minor-operations-room-thumbnail.jpeg',
    imageAlt: 'Minor operations room',
    heading: I18n.t(
      'room_types_landing_page.minor_operations.explainer.item.heading'
    ),
    text: I18n.t(
      'room_types_landing_page.minor_operations.explainer.item.text'
    ),
    link: '/book-minor-operations-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.minor_operations.explainer.item.button_text'
    ),
  },
  {
    imageUrl: '../images/landing/treatment-room-thumbnail.jpeg',
    imageAlt: 'Treatment room',
    heading: I18n.t('room_types_landing_page.treatment.explainer.item.heading'),
    text: I18n.t('room_types_landing_page.treatment.explainer.item.text'),
    link: '/book-treatment-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.treatment.explainer.item.button_text'
    ),
  },
  {
    imageUrl: '../images/landing/examination-room-thumbnail.jpeg',
    imageAlt: 'Examination room',
    heading: I18n.t(
      'room_types_landing_page.examination.explainer.item.heading'
    ),
    text: I18n.t('room_types_landing_page.examination.explainer.item.text'),
    link: '/book-examination-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.examination.explainer.item.button_text'
    ),
  },
  {
    imageUrl: '../images/landing/consulting-room-thumbnail.jpeg',
    imageAlt: 'Consulting room',
    heading: I18n.t(
      'room_types_landing_page.consulting.explainer.item.heading'
    ),
    text: I18n.t('room_types_landing_page.consulting.explainer.item.text'),
    link: '/book-consulting-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.consulting.explainer.item.button_text'
    ),
  },
];

export const nonClinicalRoomTypes = [
  {
    imageUrl: '../images/landing/counselling-room-thumbnail.jpeg',
    imageAlt: 'Counselling room',
    heading: I18n.t(
      'room_types_landing_page.counselling.explainer.item.heading'
    ),
    text: I18n.t('room_types_landing_page.counselling.explainer.item.text'),
    link: '/book-counselling-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.counselling.explainer.item.button_text'
    ),
  },
  {
    imageUrl: '../images/landing/group-room-thumbnail.jpeg',
    imageAlt: 'Group room',
    heading: I18n.t('room_types_landing_page.group.explainer.item.heading'),
    text: I18n.t('room_types_landing_page.group.explainer.item.text'),
    link: '/book-group-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.group.explainer.item.button_text'
    ),
  },
  {
    imageUrl: '../images/landing/meeting-room-thumbnail.jpeg',
    imageAlt: 'Meeting room',
    heading: I18n.t('room_types_landing_page.meeting.explainer.item.heading'),
    text: I18n.t('room_types_landing_page.meeting.explainer.item.text'),
    link: '/book-meeting-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.meeting.explainer.item.button_text'
    ),
  },
  {
    imageUrl: '../images/landing/office-room-thumbnail.jpeg',
    imageAlt: 'Office room',
    heading: I18n.t('room_types_landing_page.office.explainer.item.heading'),
    text: I18n.t('room_types_landing_page.office.explainer.item.text'),
    link: '/book-office-rooms',
    buttonText: I18n.t(
      'room_types_landing_page.office.explainer.item.button_text'
    ),
  },
];
